#header {
  width: 100%;
  
  background-color: #212121;
  
  position: fixed;
  top: 0;
  z-index: 100;
}

.navbar {
  width: 1280px;
  height: 46px;
  margin: 0 auto;
  
  display: flex;
  justify-content: space-around;
}
nav a:hover {
  font-weight: 300;
}

/* HEADER LEFT *****************************************************/
.header__left {
  width: 30%;
}

.header__logo {
  width: 25%;
  height: 100%;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 1px;
  text-decoration: overline;

  background-color: #FFFF5D;
  color: black;

  display: flex;
  justify-content: center;
  align-items: center;
}

.nav_links {
  width: 75%;
  height: 100%;

  display: flex;
  justify-content: space-around;
}
.nav_links a {
  width: 100%;
  height: 100%;

  border-right: 1px solid #535353;

  display: flex;
  justify-content: space-around;
  align-items: center;
}


/* HEADER MIDDLE / SEARCH ****************************************/
.header__middle {
  width: 40%;
  padding: 6px 10px;

  border-right: 1px solid #535353;
}
.header__searchForm {
  height: 100%;
  padding: 0 10px;

  background-color: #111213;
  border-radius: 6px;

  display: flex;
  align-items: center;
}
.header__searchInput {
  width: 100%;
  height: 70%;
  
  font-family: inherit;
  font-size: 12px;
  font-weight: 300;

  background-color: transparent;
  color: white;
  border: none;
  outline: transparent;
}


/* HEADER RIGHT *************************************************/
.header__right {
  width: 30%;
  position: relative;
  justify-content: space-between;
}

.header__upload {
  width: 25%;
  height: 100%;

  border-right: 1px solid #535353;

  display: flex;
  justify-content: center;
  align-items: center;
}
.header__upload a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__profileNavBtn {
  width: 55%;
  height: 100%;
  padding: 10px;

  cursor: pointer;

  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.header__profileNavBtn::after {
  content: '\25BC';
  font-size: 12px;
}

.header__userPfp {
  height: 2em;
  width: 2em;
  border-radius: 100%;
}
.header__username {
  width: 110px;
  position: relative;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.login_signup_btn_container {
  width: 200px;
  display: flex;
  justify-content: space-between;
  margin-left: 14px;
}

.header__about {
  min-width: 20%;
  height: 100%;
  border-bottom: 2px solid #FFFF5D;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: background .2s;
}
.header__about:hover {
  background: #FFFF5D;
}
.header__about a {
  height: 100%;
  width: 100%;

  color: white;

  position: relative;
  top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: color .2s;
}
.header__about a:hover {
  color: black;
  font-weight: 400;
}


/* PROFILE NAV DROPDOWN *****************************************************/
.profile_nav_dropdown {
  position: absolute;
  top: 32px;
  left: 0;
  width: 140px;
  border: 1px solid #535353;
  border-top: none;

  text-align: left;

  background-color: #212121;
}
.profile_nav_dropdown li {
  cursor: pointer;
  transition: background .05s, color .05s;
}
.profile_nav_dropdown li a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  transition: background .05s, color .05s;
}
.profile_nav_dropdown *:hover {
  background: #FFFF5D;
  color: black;
  font-weight: 400;
}
