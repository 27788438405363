.song_header {
  width: 100%;
  height: 380px;
}

.song_main {
  width: 100%;
  height: auto;

  display: grid;
  grid-template-rows: 124px auto;
  grid-template-columns: auto 55% 30%;
  grid-template-areas: 
    "top top side"
    "left mid side";
}
.song__actions,
.song__profile_card,
.song__description,
.song_sidebar {
  padding: 16px;
}

.song__actions {
  grid-area: top;
}

.song__profile_card {
  grid-area: left;
}

.song__description {
  text-align: left;
}

.song__body {
  grid-area: mid; 
  
  border-right: 1px solid #535353;
}

.song_sidebar {
  grid-area: side;
  max-width: 384px;
  position: sticky;
  top: 0; right: 0;
}
