#profile_page {
  width: 100%;
  display: grid;
  grid-template-columns: 70% auto;
  grid-template-rows: 300px auto;
  grid-template-areas: 
    "top top"
    "left right";
  gap: 16px 16px;
}

.profile__header {
  grid-area: top;
  width: 100%;
  max-height: 300px;
  
  overflow: hidden;
  
  position: relative;
}

.profile__header img {
  object-fit: cover;
  width: 100%;

  z-index: -1;
}

.user_info_container {
  padding: 16px 48px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  
  display: flex;
  align-items: center;
}

.user_info_container img {
  min-width: 200px;
  width: 200px;
  min-height: 200px;
  height: 200px;
  
  object-fit: cover;
  
  border-radius: 100%;
  z-index: 1;
}

.profile__info {
  width: 700px;
  height: 200px;
  margin: 32px;
  
  text-align: left;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.profile__info h2 {
  font-size: 24px;
  margin-top: 0;
  font-style: normal;
}
.profile__info > * {
  max-width: 620px;
  padding: 6px 10px;
  
  word-wrap: break-word;
  text-align: left;
  
  background-color: #212121;
}




.profile__body {
  grid-area: left;
}


/* SIDEBAR **********************************************/
.profile__sidebar .sidebar_container {
  width: 100%;
  padding: 16px;
  border-left: 1px solid #535353;
  
}
.profile__sidebar .sidebar_container h4 {
  padding: 8px 0;
  border-bottom: 1px solid #535353;
}


#profile_sidebar {
  /* this is too small but allows position sticky to work, overflow is okay */
  height: 50px;
  position: sticky;
  top: 50px;
  right: 0;
}

.line_clamp  {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
