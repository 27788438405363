.song_card_container,
.song_card_small_container {
  width: 100%;
  height: 160px;
  margin: 24px 0;

  display: flex;
  align-items: center;
}


.song_card_container > a > img {
  height: 160px;
  width: 160px;
  margin-right: 16px;
  object-fit: cover;
}

.song_card__content,
.song_card_small__content {
  width: 100%;
  height: 100%;
}

.song_card__top,
.song_card__bottom {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* TOP *****************************************/

.top__play,
.top__pause {
  height: 40px;
  min-width: 40px;
  margin-right: 8px;
  
  background-color: #FFFF5D;
  border-radius: 100%;
  cursor: pointer;
}
.top__play::before {
  content: '▶';
  color: #212121;
  font-size: 28px;
  position: relative;
  right: -2px;
}
.top__pause::before {
  content: '||';
  color: #212121;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: -5px;
  
  position: relative;
  left: -2px;
  bottom: -1px;
}

.top__title_artist {
  height: 40px;
  width: 100%;
  max-width: 920px;
  
  text-align: left;
  

}
.top__title_artist a {
  display: block;
  max-width: 920px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.song_badge_container .top__title_artist a  {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.top__right {
  width: 160px;
  height: 100%;
  
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.song_card_container .genre {
  padding: 2px 16px;
  margin: 0;
  font-size: 12px;
}


/* MIDDLE *****************************************/
.song_card__waveform img {
  height: 100px;
  width: 100%;
  padding: 16px;
  filter: hue-rotate(60deg) brightness(1.5);
}



/* BOTTOM ******************************************/
.song_card__bottom {
  align-items: flex-end;
}

.bottom__buttons {
  height: 24px;
  display: flex;
  justify-content: space-around;
  gap: 8px;
}

.bottom__buttons button {
  font-size: 11px;
}
.bottom__buttons button a {
  width: 100%;
  display: flex;
  align-items: center;
  transition: background .05s, color .05s;
}
.bottom__buttons button:hover,
.bottom__buttons a:hover {
  background: #FFFF5D;
  border-color: transparent;
  color: black;
  font-weight: 400;
}

.bottom__right {
  display: flex;
  gap: 12px;
}



/* SONG CARD SMALL *******************************************
*************************************************************/

.song_card_small_container {
  height: 64px;
  width: 100%;
  margin: 12px 0;
}

.song_card_small__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.song_card_small_container > a > img {
  height: 64px;
  width: 64px;
  margin-right: 8px;
  object-fit: cover;
}

.song_card_small__top span {
  max-width: 280px;
  display: block;
  word-wrap: break-word;
}
