.artist_badge {
  width: 134px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  
}
.artist_badge > a {
  border-radius: 100%;
}
.artist_badge > a > img {
  width: 134px;
  height: 134px;
  object-fit: cover;
  border-radius: 100%;
  /* box-shadow: 10px 10px #535353; */
}

.badge__info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
