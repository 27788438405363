.splash_container {
  width: 100%;
}


/* HEADER -**********************************************/
.splash_header {
  width: 100%;
  height: 380px;
  
  overflow: hidden;
  
  position: relative;
  display: flex;
  align-items: center;
}
.splash_bg {
  transform: rotate(90deg);
  height: 1300px;
  position: absolute;
  top: -400px; left: 326px;
  opacity: .95;
  z-index: -2;
}

.splash__greeting {
  width: 100%;
  height: 200px;
  padding: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.greeting__left, .greeting__right {
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 5px;
  text-align: left;
}



/* BUTTONS *********************************************/
.splash_buttons {
  width: 100%;
  height: 100px;
  margin-bottom: 24px;
  
  background-color: #2420204b;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}
.splash_buttons #login_button,
.splash_buttons #signup_button {
  width: 300px;
  margin: 32px 0;
  padding: 24px;
  font-size: 16px;
  font-style: normal;
}



/* GRID ********************************************************/
.splash__grid {
  width: 100%;
  margin-bottom: 24px;
  
  display: grid;
  grid-template-columns: repeat(5, 256px);
  grid-template-rows: 300px 300px;
  grid-auto-flow: row;
}



/* CALL TO ACTION *************************************************/
.cta {
  width: 100%;
  height: 380px;
  margin-bottom: 24px;
  background-color: #535353;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cta h2 {
  font-style: normal;
  letter-spacing: 2px;
}

.cta h3 {
  font-style: italic;
}

.cta #signup_button {
  width: 300px;
  margin: 32px 0;
  padding: 24px;
  font-size: 16px;
  font-style: normal;
}

.cta #login_button {
  padding: 16px;
  margin: 0 0 0 24px;

  border-color: #FFFF5d;
  color: #FFFF5d
}

.cta #login_button:hover {
  background-color: #FFFF5D;
  color: black;
  font-weight: 400;
}
