.search {
  height: 100%;
  position: relative;
}

#search_bg {
  position: fixed;
  top: 46px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.553);
}

.search_filter {
  width: 514px;
  max-width: 514px;
  max-height: 400px;
  margin: 0;
  
  background-color: #111213;
  overflow-x: hidden;
  overflow-y: scroll;
  
  top: 160px; margin: 0 auto;
}


#search_message {
  width: 100%;
  letter-spacing: 1.5px;
  padding: 10px;
  
  text-align: right;
  font-style: italic;
  font-size: 12px;
  color: #b3b3b3;
  
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
#search_message:hover {
  background: #FFFF5D;
  color: black;
  font-weight: 500;
}


.search_filter li {
  padding: 0 5px;
  letter-spacing: 1.5px;
}

.search_filter li,
.search_filter li a {
  width: 100%;
  max-width: 514px;
  min-height: 36px;
  padding: 0 5px;
  
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background .05s, color .05s;
}
.search_filter li a span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search_filter *:hover {
  background: #FFFF5D;
  color: black;
  font-weight: 400;
}
