.actions_container {
  height: 100%;
  border-bottom: 1px solid #535353;
}


/* COMMENT **********************************/
.write_comment {
  display: flex;
  align-items: center;
  height: 50%;
  margin: 0 auto;
}

.write_comment img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.comment_form {
  height: 40px;
  background-color: #212121;
  width: 100%;
  padding: 0 16px;
  display: flex;
  align-items: center;
}
.comment_form .form_group {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.comment_form input {
  width: 91%;
  background-color: #111213;
  border-color: #111213;
  font-weight: 400;
}



/* BUTTONS ****************************************/
.actions_container .bottom__buttons {
  width: 100%;
  height: 50%;
  
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.actions_container .bottom__right {
  position: absolute;
  right: 0;
  color: #b3b3b3;
}
