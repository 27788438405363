@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

* {
  box-sizing: border-box; /* CHECK HERE IF THINGS ARE WRONG WHEN COPYING OVER */
}

body {
  max-width: 1280px;
  min-height: 1200px;
  margin: 46px auto 64px;

  font-family: 'Roboto Mono', monospace;
  font-size: 14px;
  font-weight: 200;
  letter-spacing: -0.5px;

  background-color: #111213;
  color: #ffffff;

  display: flex;
  justify-content: center;
}


/* ELEMENT STYLING **********************************************************/
main {
  width: 1280px;
}

h1, h2 {
  /* border: 2px solid #FF5500; */
  padding: .5em 1em;

  font-style: italic;
  font-weight: 400;
  letter-spacing: 5px;

  display: inline-block;
}

h3, h4 {
  font-weight: 200;
  letter-spacing: -0.5px;
  margin: 0;
}

a {
  text-decoration: none;
  color: white;
}
a:hover:not(.header__logo)  {
  font-weight: 300;
}

small {
  color: #b3b3b3;
}

ul {
  padding: 0;
  list-style-type: none;
}

li {
  height: 3em;
  padding: 0 8px;
  font-size: 12px;
  display: flex;
  align-items: center;
}

#root {
  width: 1280px;
  min-height: 1200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blur {
  filter: blur(10px);
}

.hidden {
  display: none;
}

#loading {
  width: 100%;
  height: 1000px;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, 0);
}


/* OOP CLASSES **************************************************************/
.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flexRowBetween {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flexCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flexColLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.superCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.alignItems {
  display: flex;
  align-items: center;
}

.italic {
  font-style: italic;
}

.textAlignLeft {
  text-align: left;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fixed-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


/* BUTTONS *****************************************************************/

/* button structure */
.btn {
  margin: 0;
  padding: 1em;
  height: 2em;
  /* max-width: 5em; */
  border: 1px solid;
  border-radius: 4px;

  font-family: 'Roboto Mono', monospace;
  font-size: 12px;
  font-weight: 200;
  letter-spacing: -0.5px;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  transition: margin-top 0.15s, background 0.15s, border-color 0.15s, color 0.15s;
}
.btn:hover {
  box-shadow: 0px 0px 4px 1px rgba(216, 216, 216, 0.226);
}
.btn_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* solid yellow button skin */
.btn--primary {
  font-weight: 400;
  color: black;
  background: #FFFF5D;
  border-color: #FFFF5D;
}
/* yellow outlined button skin */
.btn--primary--outline {
  border: 2px solid #FFFF64;
  color: #FFFF64;
  transition: background 0.15s, color 0.15s, border-color 0.15s;
}
.btn--primary--outline:hover {
  background-color: #FFFF5D;
  color: black;
  font-weight: 400;
}
.btn--primary:active,
.btn--primary--outline:active {
  background: #c9c950;
  border-color: #c9c950;
}

/* solid white button skin */
.btn--secondary {
  background: transparent;
  border: none;
  color: white;
}
/* white outlined button skin */
.btn--secondary--outline {
  border: 1px solid #535353;
  color: #ffffff;
  background-color: transparent;
  transition: background 0.15s, color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
/* white buttons when clicked */
.btn--secondary--outline:active {
  border-color: #b3b3b36e;
}

/* "liked" button */
.btn--liked {
  border: 1px solid #d73543;
  color: #ffffff;
  /* background: linear-gradient(rgba(168, 58, 131, 0.212), rgba(228, 51, 78, 0.233), #fb624044); */
  background: transparent;
  transition: background 0.15s, color 0.15s, border-color 0.15s, box-shadow 0.15s;
}


/* FORMS *********************************************************************/
/* form containers */
.form_container {
  width: 60%;
  margin: 0 auto;
  border: 1px solid #535353;

  box-shadow: 8px 8px #535353;
}

/* form container for input and label etc. */
.form_group {
  padding: .5em 0;
  font-size: 12px;
  text-align: left;

  display: flex;
  flex-direction: column;
}

/* form input */
.form_input {
  height: 26px;
  margin: 6px 0;
  padding: 2px 8px;

  font-family: inherit;
  font-weight: inherit;

  background-color: #212121;
  color: white;
  border: 1px solid #535353;
  border-radius: 4px;
  outline: none;
}
.form_input:focus {
  background-color: #FFFF5D;
  color: black;
  font-weight: 400;
}
