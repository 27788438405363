.song_badge {
  min-width: 200px;
  height: 260px;
  margin: 16px;
  padding: 8px;
}

.song_badge__art {
  width: 100%;
  height: 196px;
  position: relative;
}

.song_badge__art img {
  width: 160px;
  height: 160px;
  object-fit: cover;
  border-radius: 10px;
  border: 1px solid #535353;
  
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.hallway {
  width: 160px;
  height: 160px;
  object-fit: cover;
  border: 1px solid #535353;
  border-radius: 10px;
  background-color: #363636;
  box-shadow: 10px 10px 0 0 #212121 ;
  
  position: absolute;
  top: 10px;
  left: 10px;
}


/* PLAY/PAUSE BUTTONS **************************/

.song_badge .song_header__play,
.song_badge .song_header__pause {
  z-index: 2;
  position: absolute;
  top: 50px; left: 50px;
  opacity: 0;
  transition: opacity .15s;
}

#pause, #play {
  opacity: 1;
}
