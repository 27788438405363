#footer {
  width: 100%;
  border-top: 1px solid #535353;
  
  background-color: #111213;
  
  position: fixed;
  bottom: 0;
  z-index: 100;  
}

.play_controls {
  width: 1280px;
  height: 60px;
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
}

.player_wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}


/* CURRENT SONG DETAILS */
.play_controls__details {
  height: 100%;
  width: 350px;
  max-width: 350px;
  
  font-size: 12px;
  
  display: flex;
  align-items: center;
}

.play_controls__details img {
  width: 40px;
  height: 40px;
  margin: 0 10px;
  object-fit: cover;
}

.play_controls__details span {
  width: 220px;
  
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}


.like_button_footer:hover {
  cursor: pointer;
}
