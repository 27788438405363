.signup_container {
  display: grid;
  grid-template-rows: 100px 320px 180px 60px;
  grid-template-columns: 360px 300px;
  column-gap: 2%;
  grid-template-areas:
  "top top"
  "left-top right"
  "left-bottom right"
  "bottom bottom"
  ;
}

.signup__header {
  grid-area: top;
  text-align: right;
}

.signup_form {
  grid-area: left-top;
  padding-right: 1em;
}

.signup_image {
  grid-area: right;
  width: 280px;
  height: 100%;

  object-fit: cover;

}

.signup__error_container {
  grid-area: left-bottom;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.signup__error_container li {
  height: 24px;
  padding: 0;
  display: flex;
  justify-content: flex-start;
}
.signup__error_container li::before {
  content: '*';
  font-weight: 500;

  color: red;
}


.signup__bottom {
  grid-area: bottom;
  margin: 16px 0 0;
  padding: 10px;
  border-top: 1px solid #535353;

  display: flex;
  justify-content: flex-end;
}
