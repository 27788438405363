.comment__wrapper {
  width: 100%;
  height: 100%;
  margin: 16px 0;
  
  font-size: 13px;
  
  position: relative;
  display: flex;
  justify-content: space-between;
}


.comment__left {
  height: 100%;
  width: 100%;
  
  text-align: left;
  
  display: grid;
  grid-template-columns: 54px auto;
  grid-template-rows: 24px auto;
  grid-template-areas: 
    'left top'
    'left bottom';
  align-content: center;
  align-items: center;
}
.comment__left img {
  grid-area: left;
  
  width: 42px;
  height: 42px;
  object-fit: cover;
  border-radius: 100%;
}

.comment__right {
  min-width: 140px;
  display: flex;
  justify-content: right;
  align-items: center;
}
.comment__right.createdAt {
  align-items: flex-start;
}
