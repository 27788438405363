.login_container {
  display: grid;
  grid-template-rows: 100px 200px 250px 64px;
  grid-template-columns: 42% 56%;
  column-gap: 2%;
  grid-template-areas:
  "top top"
  "left right-top"
  "left right-bottom"
  "bottom bottom"
  ;
}

.login_image {
  grid-area: left;
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.login_form {
  grid-area: right-top;
  padding-left: 1em;
}

.login_btn_container {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.login__error_container {
  grid-area: right-bottom;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.login__error_container li {
  height: 24px;
  padding: 0;
  display: flex;
  justify-content: flex-end;
}
.login__error_container li::before {
  content: '*';
  font-weight: 500;

  color: red;
}

.login__bottom {
  grid-area: bottom;
  
  width: 460px;
  margin: 16px 0 0;
  padding: 10px;
  border-top: 1px solid #535353;
  
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  color: #b3b3b3;

  display: flex;
  justify-content: center;
}
